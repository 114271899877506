import { NavigationClient, NavigationOptions } from '@azure/msal-browser'
import { NavigateFunction } from 'react-router-dom'

/**
 * Extending the default NavigationClient allows you to overwrite just navigateInternal while continuing to use the default navigateExternal function
 * If you would like to overwrite both you can implement INavigationClient directly instead
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/performance.md
 */
class CustomNavigationClient extends NavigationClient {
  navigate: NavigateFunction
  constructor(navigate: NavigateFunction) {
    super()
    this.navigate = navigate
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useHistory hook provided by react-router-dom to take advantage of client-side routing
   * @param url
   * @param options
   */
  async navigateInternal(url: string, options: NavigationOptions) {
    // url will be absolute, you will need to parse out the relative path to provide to the history API
    const relativePath = url.replace(window.location.origin, '')

    this.navigate(relativePath, { replace: options.noHistory })

    return false
  }
}

export { CustomNavigationClient }
