import useSWR from 'swr'

import { CompanyResponse } from '~/types'

/**
 * Hook fetching the company data for the currently logged in user.
 */

const useCompany = () => {
  return useSWR<CompanyResponse>('users/me/company')
}

export default useCompany
