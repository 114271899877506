import {
  faBrowsers,
  faBuilding,
  faSignOutAlt,
  faUsers
} from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Nav, Theme, useApplyTheme } from '@intility/bifrost-react'
import IntilityApps from '@intility/platform-toolkit/IntilityApps'
import '@intility/platform-toolkit/css/IntilityApps.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'

import useAppsAccess from '~/api/useAppsAccess'
import useCompany from '~/api/useCompany'
import { logout } from '~/auth/instance'
import useInternalTranslation from '~/hooks/useInternalTranslation'
import { SupportedLocale, replaceLocale } from '~/utils/locale'
import NavLinkInternal from './NavLinkInternal'
import styles from './Navigation.module.css'
import ProfilePicture from './ProfilePicture'

const Navigation = ({ children }: React.PropsWithChildren) => {
  const { t } = useInternalTranslation()
  const { locale } = useParams<'locale'>()
  const { pathname, search, hash } = useLocation()
  const navigate = useNavigate()

  // persist theme state in local storage.
  const [theme, setTheme] = useLocalStorageState<Theme>('bfTheme', {
    defaultValue: 'system'
  })
  // keep document theme in sync with state
  useApplyTheme(theme)

  // Company info
  const { data: company } = useCompany()

  // Apps with access
  const { data: appsWithAccess } = useAppsAccess()

  function setLocale(newLocale: SupportedLocale) {
    navigate(replaceLocale(pathname + search + hash, newLocale), {
      preventScrollReset: true
    })
  }

  return (
    <Nav
      className='bf-elements'
      logo={
        <NavLinkInternal to='/' className='bf-neutral-link'>
          <Nav.Logo logo='/assets/apps/platformManager.svg'>
            <span className='to-small'>Platform Manager</span>
            <span className='from-small'>Intility Platform Manager</span>
          </Nav.Logo>
        </NavLinkInternal>
      }
      top={
        <>
          {company && (
            <Nav.Item icon={faBuilding}>
              <span className={styles.companyName}>{company?.companyName}</span>
            </Nav.Item>
          )}

          <IntilityApps data={appsWithAccess} />

          <Nav.Group
            name={<ProfilePicture size={28} />}
            className={styles.profileDropdown}
          >
            <a href='https://my.intility.com'>
              <Nav.Item hideCircle>
                <img
                  src='/assets/apps/myIntility.svg'
                  alt=''
                  style={{ width: '21px', height: '21px' }}
                />
                <span style={{ marginLeft: '8px' }}>My Intility</span>
              </Nav.Item>
            </a>

            <hr />

            <section>
              <Nav.Header>{t('Color theme')}</Nav.Header>
              <Checkbox
                type='radio'
                label={t('Dark')}
                name='color-theme'
                checked={theme === 'dark'}
                onChange={() => setTheme('dark')}
              />
              <Checkbox
                type='radio'
                label={t('Light')}
                name='color-theme'
                checked={theme === 'light'}
                onChange={() => setTheme('light')}
              />
              <Checkbox
                type='radio'
                label={t('System')}
                name='color-theme'
                checked={theme === 'system'}
                onChange={() => setTheme('system')}
              />
            </section>

            <section>
              <Nav.Header>{t('Language')}</Nav.Header>
              <Checkbox
                type='radio'
                name='language'
                label='English'
                checked={locale === 'en-us'}
                onChange={() => setLocale('en-us')}
              />
              <Checkbox
                type='radio'
                name='language'
                label='Norsk'
                checked={locale === 'nb-no'}
                onChange={() => setLocale('nb-no')}
              />
              <Checkbox
                type='radio'
                name='language'
                label='Svenska'
                checked={locale === 'sv-se'}
                onChange={() => setLocale('sv-se')}
              />
            </section>

            <hr />

            <button onClick={() => logout()}>
              <Nav.Item icon={faSignOutAlt} hideCircle>
                {t('Sign out')}
              </Nav.Item>
            </button>
          </Nav.Group>
        </>
      }
      side={
        <>
          <NavLinkInternal to='' end>
            <Nav.Item icon={faBrowsers}>{t('Applications')}</Nav.Item>
          </NavLinkInternal>
          <NavLinkInternal to='/contacts'>
            <Nav.Item icon={faUsers}>{t('Contacts')}</Nav.Item>
          </NavLinkInternal>
        </>
      }
    >
      {children}
    </Nav>
  )
}

export default Navigation
