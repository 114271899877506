import { AccountInfo } from '@azure/msal-browser'
import { useAccount } from '@azure/msal-react'
import { Navigate } from 'react-router-dom'

import { computeLocale, defaultLocale } from '~/utils/locale'

// The user's preferred language, if set. Sourced from their home tenant, in guest access scenarios. Formatted LL-CC ("en-us").
const userPreferredLocaleClaim = 'xms_pl'

// The resource tenant's preferred language, if set. Formatted LL ("en").
const tenantPreferredLanguageClaim = 'xms_tpl'

/**
 * Gets the user's preferred language, if set. Sourced from id token claim xms_pl.
 * @param account Msal account object
 * @returns The users preferred language, formatted LL-CC ("en-us"), or undefined.
 */
function getUserPreferredLanguage(account: AccountInfo) {
  const userPreferredLocale = account.idTokenClaims?.[userPreferredLocaleClaim]

  if (typeof userPreferredLocale === 'string') {
    return userPreferredLocale
  }

  return undefined
}

/**
 * Gets the tenant's preferred language, if set. Sourced from id token claim xms_tpl.
 * @param account Msal account object
 * @returns The tenant's preferred language, formatted LL ("en"), or undefined.
 */
function getTenantPreferredLanguage(account: AccountInfo) {
  const tenantPreferredLanguage =
    account.idTokenClaims?.[tenantPreferredLanguageClaim]

  if (typeof tenantPreferredLanguage === 'string') {
    return tenantPreferredLanguage
  }

  return undefined
}

/**
 * Hook that retrieves the account object from the MsalProvider and navigates to the user's preferred locale.
 * If the user locale is not set, it navigates to the tenant's preferred locale.
 * If the tenant locale is not set, it navigates to the default locale.
 */
export function usePreferredLocale() {
  const account = useAccount()

  if (!account) {
    return defaultLocale
  }

  return computeLocale(
    getUserPreferredLanguage(account) ?? getTenantPreferredLanguage(account)
  )
}

/**
 * Component that retrieves the account object from the MsalProvider and navigates to the user's preferred locale.
 * If the user locale is not set, it navigates to the tenant's preferred locale.
 * If the tenant locale is not set, it navigates to the default locale.
 */
export default function NavigateToPreferredLocale() {
  const preferredLocale = usePreferredLocale()

  return <Navigate to={'/' + preferredLocale} replace />
}
