import { wrapCreateBrowserRouter } from '@sentry/react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route
} from 'react-router-dom'

import Authentication from './auth/Authentication'
import App from './components/App'
import ErrorPage from './components/ErrorPage'
import NavigateToPreferredLocale from './components/NavigateToPreferredLocale'
import Contacts from './pages/Contacts/Contacts'
import Applications from './pages/Dashboard/Applications'

/**
 * Sentry integration
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
 */
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

/**
 * The applications router, using react-router data APIs
 * @see https://reactrouter.com/en/main/routers/create-browser-router
 */
const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={<ErrorPage />}
      element={
        <Authentication>
          <Outlet />
        </Authentication>
      }
    >
      <Route
        path=':locale'
        element={
          <App>
            <Outlet />
          </App>
        }
      >
        <Route index element={<Applications />} />
        <Route path='contacts' element={<Contacts />} />
      </Route>
      <Route index element={<NavigateToPreferredLocale />} />
    </Route>
  )
)

export { router }
