import { PropsWithChildren } from 'react'
import { SWRConfig } from 'swr'

import { authorizedFetch } from '~/auth'
import constants from '../constants'

const defaultFetcher = (path: string) =>
  authorizedFetch(`${constants.server_url}/${path}`).then(response =>
    response.json()
  )

const SWRProvider = ({ children }: PropsWithChildren) => {
  return (
    <SWRConfig
      value={{
        fetcher: defaultFetcher,
        focusThrottleInterval: 60000,
        errorRetryInterval: 60000
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SWRProvider
