import { matchPath } from 'react-router-dom'

export const supportedLocales = ['en-us', 'nb-no', 'sv-se'] as const
export type SupportedLocale = (typeof supportedLocales)[number]

export const defaultLocale = supportedLocales[0]
const findFirstPartOfPath = /^\/([^/]*)/

/**
 * Replaces locale part of url path
 * @param urlPath current path (i.e. '/en-us/path/to/page')
 * @param locale new locale (i.e. 'nb-no')
 * @returns new path with replaced locale (i.e. '/nb-no/path/to/page')
 */
export const replaceLocale = (urlPath: string, locale: SupportedLocale) =>
  urlPath.replace(findFirstPartOfPath, '/' + locale)

export const getLocaleFromPath = (pathname = '') => {
  const match = matchPath('/:locale/*', pathname)

  return match?.params.locale
}

export const computeLocale = (locale?: string) => {
  if (locale) {
    if (locale.includes('-')) {
      // check for exact match of ll-cc
      const exactLocaleMatch = supportedLocales.find(
        supportedLocale =>
          supportedLocale.toLowerCase() === locale.toLowerCase()
      )

      if (exactLocaleMatch) return exactLocaleMatch

      // check for ll match of ll-cc
      const language = locale.toLowerCase().split('-')[0]

      const languagePartMatch = supportedLocales.find(supportedLocale =>
        supportedLocale.toLowerCase().startsWith(language)
      )

      if (languagePartMatch) return languagePartMatch

      // check for cc match of ll-cc
      const country = locale.toLowerCase().split('-')[1]

      const countryPartMatch = supportedLocales.find(supportedLocale =>
        supportedLocale.toLowerCase().endsWith(country)
      )

      if (countryPartMatch) return countryPartMatch
    } else {
      // assume locale comes with only ll
      const localeMatch = supportedLocales.find(supportedLocale =>
        supportedLocale.toLowerCase().startsWith(locale.toLowerCase())
      )

      if (localeMatch) return localeMatch

      if (/^(no|nn)$/i.test(locale)) {
        return 'nb-no'
      }
    }
  }

  return defaultLocale
}
