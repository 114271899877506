import { Button, Grid, Skeleton } from '@intility/bifrost-react'
import { useState } from 'react'

import useContacts from '~/api/useContacts'
import teamsLogo from '~/assets/icons/microsoft-teams.webp'
import ErrorPage from '~/components/ErrorPage'
import Header from '~/components/Header'
import MissingData from '~/components/MissingData'
import useInternalTranslation from '~/hooks/useInternalTranslation'
import styles from './Contacts.module.css'
import ContactCard, { SkeletonCard } from './components/ContactCard'
import GroupConversationModal from './components/GroupConversationModal'

const Contacts = () => {
  const { t } = useInternalTranslation()
  const { data: contacts, isLoading, error } = useContacts()
  const [open, setOpen] = useState(false)

  if (error) {
    return (
      <>
        <Header title={t('Your Intility contacts')} />
        <div className={styles.errorContainer}>
          <ErrorPage>
            <Grid gap={24}>
              <h4>{t('contacts_error')}</h4>
              <div>
                <Button
                  onClick={() => window.location.reload()}
                  style={{ marginRight: '24px' }}
                >
                  {t('Refresh')}
                </Button>
                <a
                  className='bf-button'
                  href='https://my.intility.com/support'
                  target='_blank'
                  rel='noreferrer'
                >
                  {t('Support')}
                </a>{' '}
              </div>
            </Grid>
          </ErrorPage>
        </div>
      </>
    )
  }

  return (
    <>
      <Header
        title={t('Your Intility contacts')}
        description={t('intility_contacts_description')}
        // breadcrumbs={[{ name: t('Intility contacts') }]}
      >
        <Button
          onClick={() => setOpen(true)}
          className={styles.startGroupCall}
          disabled={!contacts || contacts.length === 0}
          state='neutral'
        >
          <img className={styles.teamsLogo} alt='' src={teamsLogo} />
          {t('Start group conversation')}
        </Button>
      </Header>

      <div className={styles.contactsContainer}>
        <Grid medium={2} gap={24}>
          {!contacts && isLoading && (
            <Skeleton repeat={6}>
              <SkeletonCard />
            </Skeleton>
          )}
          {contacts &&
            contacts.length > 0 &&
            !isLoading &&
            contacts.map(contact => (
              <ContactCard key={contact.name} contact={contact} />
            ))}
        </Grid>
      </div>

      {/* No data */}
      {contacts && contacts?.length === 0 && !isLoading && (
        <MissingData message={t('Wops, no contacts found')} />
      )}

      {contacts && (
        <GroupConversationModal
          open={open}
          setOpen={setOpen}
          contacts={contacts}
        />
      )}
    </>
  )
}

export default Contacts
