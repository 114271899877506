import { Button, CheckboxCard, Grid, Modal } from '@intility/bifrost-react'
import { useState } from 'react'

import teamsLogo from '~/assets/icons/microsoft-teams.webp'
import useInternalTranslation from '~/hooks/useInternalTranslation'
import { ContactType } from '~/types'
import styles from './GroupConversationModal.module.css'

type GroupConversationModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  contacts: ContactType[]
}

const GroupConversationModal = ({
  open,
  setOpen,
  contacts
}: GroupConversationModalProps) => {
  const { t } = useInternalTranslation()
  const [selectedContacts, setSelectedContacts] = useState<string[]>([])

  const handleCheckboxChange = (email: string) => {
    setSelectedContacts(prev =>
      prev.includes(email)
        ? prev.filter(contact => contact !== email)
        : [...prev, email]
    )
  }

  const startGroupCall = () => {
    if (selectedContacts.length > 0) {
      const emails = selectedContacts.join(',')
      const teamsUrl = `https://teams.microsoft.com/l/chat/0/0?users=${emails}`
      window.open(teamsUrl, '_blank')
    }
  }

  return (
    <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
      <Grid gap={24} className={styles.groupConversationModalContent}>
        <Grid gap={4}>
          <div className={styles.header}>
            {t('group-conversation-modal-title')}
          </div>
          <p> {t('group-conversation-modal-subtitle')}</p>
        </Grid>
        <Grid gap={8} small={2}>
          {contacts &&
            contacts.map(contact => (
              <CheckboxCard
                key={contact.email}
                checked={
                  contact.email
                    ? selectedContacts.includes(contact.email)
                    : false
                }
                label={
                  <div className={styles.checkboxLabel}>
                    <img
                      className={styles.contactImage}
                      src={`data:profilePhoto/png;base64, ${contact.profilePhoto}`}
                      alt=''
                    />

                    <span>{contact.name}</span>
                  </div>
                }
                onChange={() =>
                  contact.email && handleCheckboxChange(contact.email)
                }
              />
            ))}
        </Grid>

        <Button
          onClick={startGroupCall}
          className={styles.startGroupCall}
          disabled={selectedContacts.length === 0}
          state='neutral'
        >
          <img className={styles.teamsLogo} alt='' src={teamsLogo} />
          {t('Start conversation in Teams')}
        </Button>
      </Grid>
    </Modal>
  )
}

export default GroupConversationModal
