// https://github.com/reactjs/rfcs/pull/220
// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md

import { useCallback, useInsertionEffect, useRef } from 'react'

function useEvent<T extends (...args: any[]) => any>(handler: T) {
  const handlerRef = useRef<T>()

  // In a real implementation, this would run before all type of effects
  useInsertionEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args: Parameters<T>) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current
    return fn?.(...args)
  }, []) as T
}

export default useEvent
