import i18n from 'i18next' // i18n extension - nothing to do with next.js
import { initReactI18next } from 'react-i18next'

import enUs from '../locales/en-us.json'
import nbNo from '../locales/nb-no.json'
import svSe from '../locales/sv-se.json'
import { computeLocale, getLocaleFromPath } from './locale'

// i18n Manager seems like a solid free cross-platform desktop app for handling translations
// https://sourceforge.net/projects/i18n-manager.mirror/

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      'en-US': { translation: enUs },
      'nb-NO': { translation: nbNo },
      'sv-SE': { translation: svSe }
    },
    // language code will be set by URL (App.tsx)
    lng: computeLocale(getLocaleFromPath(window?.location?.pathname)),
    fallbackLng: 'en-US',
    keySeparator: false, // we do not use keys in form object.welcome
    nsSeparator: false, // we do not use keys in form namespace:welcome
    // uncomment following line to enable debug log while in dev mode
    // debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false // react already prevents xss
    }
  })

export default i18n
