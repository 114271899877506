import useSWR from 'swr'

import { ContactType } from '../types'

/**
 * Hook fetching Intility contacts for the currently logged in users company.
 */

const useContacts = () => {
  return useSWR<ContactType[]>('users/me/company/contacts')
}

export default useContacts
