import { PropsWithChildren } from 'react'

import LocaleDeps from './LocaleDeps'
import Navigation from './Navigation'
import SWRProvider from './SWRProvider'

const App = ({ children }: PropsWithChildren) => {
  return (
    <LocaleDeps>
      <SWRProvider>
        <Navigation>{children}</Navigation>
      </SWRProvider>
    </LocaleDeps>
  )
}

export default App
