import { useTranslation } from 'react-i18next'

import { LocaleKey } from '~/types'

/**
 * Hook for translating locale keys that is union of keys that is available from all locales files
 */

const useInternalTranslation = () => {
  const { t } = useTranslation()
  return { t: (input: LocaleKey) => t(input) }
}

export default useInternalTranslation
