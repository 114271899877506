import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useEvent from '~/hooks/useEvent'
import { CustomNavigationClient } from './NavigationClient'

/**
 * Configures MSAL to use react-router's navigation.
 * Prevents MSAL from doing full reloads when navigating.
 */
const useMsalRouterIntegration = () => {
  const navigate = useEvent(useNavigate())
  const { instance } = useMsal()

  useEffect(() => {
    instance.setNavigationClient(new CustomNavigationClient(navigate))
  }, [navigate, instance])
}

export { useMsalRouterIntegration }
