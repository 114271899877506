import useSWR from 'swr'

import { AppsResponse } from '~/types'

/**
 * Hook fetching a list of apps the currently logged in user has access to.
 */

const useAppsAccess = () => {
  return useSWR<AppsResponse>('users/me/apps')
}

export default useAppsAccess
