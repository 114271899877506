import { AppType } from '~/types'

// ** Tools **
// Insight
// Intility eSign
// Intility GPT
// Intility SMS
// Roadmap

// ** Management **
// ApplicationPlatformManager
// Identity Manager
// SecurityCenter
// WorkplaceCenter

// ** Other **
// Inventory
// Invoices
// Manager365
// MonitoringInsight
// SustainabilityDashboard
// Webshop

export const appData: AppType[] = [
  {
    id: 'intilityGPT',
    logo: '/assets/apps/intilityGPT.svg',
    name: 'Intility GPT',
    url: 'https://gpt.intility.com',
    description: 'intilityGPT_description',
    group: 'tools'
  },
  {
    id: 'eSign',
    logo: '/assets/apps/eSign.svg',
    name: 'Intility eSign',
    url: 'https://esign.intility.com/',
    description: 'eSign_description',
    group: 'tools'
  },
  {
    id: 'intilitySMS',
    logo: '/assets/apps/intilitySMS.svg',
    name: 'Intility SMS',
    url: 'https://sms.intility.com',
    description: 'intilitySMS_description',
    group: 'tools'
  },
  {
    id: 'insight',
    logo: '/assets/apps/insight.svg',
    name: 'Insight',
    url: 'https://insight.intility.com',
    description: 'insight_description',
    group: 'tools'
  },
  {
    id: 'roadmap',
    logo: '/assets/apps/roadmap.svg',
    name: 'Intility Roadmap',
    url: 'https://roadmap.intility.com',
    description: 'roadmap_description',
    group: 'tools'
  },

  {
    id: 'userAdmin',
    logo: '/assets/apps/userAdmin.svg',
    name: 'Identity Manager',
    url: 'https://portal.intility.com/user',
    description: 'userAdmin_description',
    group: 'management',
    links: [
      {
        name: 'userAdmin_create_new_user',
        url: 'https://portal.intility.com/user/create'
      },
      {
        name: 'userAdmin_user_overview',
        url: 'https://portal.intility.com/user'
      },
      {
        name: 'userAdmin_unhandled_requests',
        url: 'https://portal.intility.com/request#filter?isHandled=false'
      }
    ]
  },
  {
    id: 'applicationPlatformManager',
    logo: '/assets/apps/applicationPlatformManager.svg',
    name: 'Application Platform Manager',
    url: 'https://apm.intility.com',
    description: 'applicationPlatformManager_description',
    group: 'management',
    links: [
      {
        name: 'applicationPlatformManager_cmdb',
        url: 'https://apm.intility.com/cmdb/companies/{{companyguid}}/implementations'
      },
      {
        name: 'applicationPlatformManager_assets',
        url: 'https://apm.intility.com/cmdb/companies/{{companyguid}}/assets'
      },
      {
        name: 'applicationPlatformManager_pam',
        url: 'https://apm.intility.com/pam/'
      }
    ]
  },
  {
    id: 'workplaceCenter',
    logo: '/assets/apps/workplaceCenter.svg',
    name: 'Workplace Center',
    url: 'https://workplace-center.intility.com',
    description: 'workplaceCenter_description',
    group: 'management',
    links: [
      {
        name: 'workplaceCenter_clients',
        url: 'https://workplace-center.intility.com/client-management/inventory'
      },
      {
        name: 'workplaceCenter_applications',
        url: 'https://workplace-center.intility.com/application'
      },
      {
        name: 'workplaceCenter_mobile_devices',
        url: 'https://workplace-center.intility.com/mobile-management/inventory'
      }
    ]
  },
  {
    id: 'securityCenter',
    logo: '/assets/apps/securityCenter.svg',
    name: 'Security Center',
    url: 'https://security-center.intility.com',
    description: 'securityCenter_description',
    group: 'management',
    links: [
      {
        name: 'securityCenter_information_hub',
        url: 'https://security-center.intility.com/publication'
      },
      {
        name: 'securityCenter_security_tickets',
        url: 'https://security-center.intility.com/statistics/ticket'
      },
      {
        name: 'securityCenter_services',
        url: 'https://security-center.intility.com/security-options/overview'
      }
    ]
  },

  {
    id: 'webshop',
    logo: '/assets/apps/webshop.svg',
    name: 'Intility Webshop',
    url: 'https://webshop.intility.no',
    description: 'webshop_description',
    group: 'other'
  },
  {
    id: 'invoices',
    logo: '/assets/apps/invoices.svg',
    name: 'Subscriptions & Invoices',
    url: 'https://portal.intility.com/invoice',
    description: 'invoices_description',
    group: 'other'
  },
  {
    id: 'tickets',
    logo: '/assets/apps/tickets.svg',
    name: 'Tickets',
    url: 'https://portal.intility.com/ticket',
    description: 'tickets_description',
    group: 'other'
  },
  {
    id: 'manager365',
    logo: '/assets/apps/manager365.svg',
    name: '365 Manager',
    url: 'https://365manager.intility.com',
    description: 'manager365_description',
    group: 'other'
  },
  {
    id: 'monitoringInsight',
    logo: '/assets/apps/monitoringInsight.svg',
    name: 'Monitoring Insight',
    url: 'https://monitoring.intility.com',
    description: 'monitoringInsight_description',
    group: 'other'
  },
  {
    id: 'inventory',
    logo: '/assets/apps/inventory.svg',
    name: 'Inventory',
    url: 'https://portal.intility.com/inventory/servers',
    description: 'inventory_description',
    group: 'other'
  },
  {
    id: 'sustainabilityDashboard',
    logo: '/assets/apps/sustainabilityDashboard.svg',
    name: 'Sustainability Manager',
    url: 'https://sustainability-manager.intility.com',
    description: 'sustainabilityDashboard_description',
    group: 'other'
  },
  {
    id: 'messages',
    logo: '/assets/apps/messages.svg',
    name: 'Operational Messages',
    url: 'https://portal.intility.com/report/messages',
    description: 'messages_description',
    group: 'other'
  }
]

export const myIntilityData: AppType = {
  id: 'myIntility',
  logo: '/assets/apps/myIntility.svg',
  name: 'My Intility',
  url: 'https://my.intility.com',
  description: 'myIntility_description',
  group: 'tools'
}
