import { Breadcrumbs } from '@intility/bifrost-react'
import classNames from 'classnames'

import useInternalTranslation from '~/hooks/useInternalTranslation'
import styles from './Header.module.css'
import NavLinkInternal from './NavLinkInternal'

export type Crumb = {
  path?: string
  name: string
}

const Header = ({
  title,
  description,
  breadcrumbs,
  children
}: {
  title: string
  description?: string
  breadcrumbs?: Crumb[]
  children?: React.ReactNode
}) => {
  const { t } = useInternalTranslation()

  return (
    <div className={styles.headerSection}>
      <div className={classNames(styles.headerContent + ' bf-page-padding')}>
        {breadcrumbs && (
          <Breadcrumbs>
            <Breadcrumbs.Item>
              <NavLinkInternal to='/'>{t('Home')}</NavLinkInternal>
            </Breadcrumbs.Item>
            {breadcrumbs.map(({ path, name }) => (
              <Breadcrumbs.Item key={name}>
                {path ? (
                  <NavLinkInternal to={path}>{name}</NavLinkInternal>
                ) : (
                  name
                )}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
        )}
        <div className={styles.pageHeader}>
          <div className={styles.titleAndDescription}>
            <h2>{title}</h2>
            {description && <div>{description}</div>}
          </div>
          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </div>
    </div>
  )
}
export default Header
