import { Bifrost, enUS, nbNO, svSE } from '@intility/bifrost-react'
import { PropsWithChildren, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useMsalRouterIntegration } from '~/auth/useMsalRouterIntegration'
import i18n from '~/utils/i18n.config'
import { computeLocale, supportedLocales } from '~/utils/locale'
import { usePreferredLocale } from './NavigateToPreferredLocale'

const useValidateLocale = () => {
  const { locale } = useParams<'locale'>()
  const location = useLocation()
  const navigate = useNavigate()
  useMsalRouterIntegration()

  const preferredLocale = usePreferredLocale()

  const validLocale = supportedLocales.includes(
    (locale?.toLowerCase() as any) ?? ''
  )

  // if locale from path is invalid, redirect to path including preferred locale
  const redirectPath =
    '/' + preferredLocale + location.pathname + location.search + location.hash
  useEffect(() => {
    if (!validLocale) {
      navigate(redirectPath, { replace: true })
    }
  }, [validLocale, redirectPath, navigate])

  const computedLocale = computeLocale(locale)
  useEffect(() => {
    i18n.changeLanguage(computedLocale)
  }, [computedLocale])

  return validLocale
}

const LocaleDeps = ({ children }: PropsWithChildren) => {
  const { locale } = useParams<'locale'>()
  const validLocale = useValidateLocale()
  let bifrostLocale = enUS
  switch (locale?.toLowerCase()) {
    case 'nb-no':
      bifrostLocale = nbNO
      break
    case 'sv-se':
      bifrostLocale = svSE
      break
  }

  if (!validLocale) return null

  return <Bifrost locale={bifrostLocale}>{children}</Bifrost>
}

export default LocaleDeps
