import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import './index.css'
import { router } from './router'
import { initializeSentry } from './utils/initializeSentry'
import registerHotJarScript from './utils/registerHotJarScript'

// https://bifrost.intility.com/#/Welcome%20to%20Bifrost/Get%20started/Installation
import '@intility/bifrost-react/dist/bifrost-app.css'

// Set default theme to dark if no theme preference is set
if (!localStorage?.getItem('bfTheme')) {
  localStorage?.setItem('bfTheme', '"dark"')
}

const rootElement = document.getElementById('root')!
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
)

if (navigator.doNotTrack !== '1') {
  registerHotJarScript()
  initializeSentry()
}
