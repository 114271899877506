import { ReactNode } from 'react'
import { NavLink, NavLinkProps, useParams } from 'react-router-dom'

type NavLinkInternalProps = Omit<NavLinkProps, 'to' | 'children'> & {
  /** The url path - absolute paths (starting with '/') will automatically create a link including the current locale */
  to: string
  /** Clickable link text (or other content) */
  children: ReactNode
}

const NavLinkInternal = ({ to, children, ...props }: NavLinkInternalProps) => {
  const { locale } = useParams()

  // for absolute links, preserve current language in link url
  if (to.length > 1 && to.startsWith('/')) {
    to = '/' + locale + to
  }

  return (
    <NavLink {...props} to={to}>
      {children}
    </NavLink>
  )
}

export default NavLinkInternal
